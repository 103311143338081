import React from "react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { useWidth } from "custom-hooks/use-width.hook";
import SlickSlider from "../slick-slider";
import { css } from "@emotion/react";
import { Grid } from "@mui/material";

function CustomCarrusel(props: {
  children: any;
  colorArrow?: string;
  placeholderItem?: JSX.Element;
  enablePlaceholder?: boolean;
  numberOfPlaceholderItems?: number;
  forcePlaceHolder?: boolean;
}) {
  const width = useWidth();
  const rows = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 4,
  };

  const arrows = css`
    color: ${props.colorArrow ?? "#1F3564"} !important;
    font-size: 2.6rem;
    padding: 2px;
    position: absolute;
    z-index: 500;
  `;

  const settings = {
    dots: false,
    infinite: props.children?.length > 4,
    speed: 500,
    slidesToShow: rows[width],
    slidesToScroll: 1,
    // centerMode: width == "xs" ? true : false,
    nextArrow: <ArrowForwardIosRoundedIcon css={arrows} />,
    prevArrow: <ArrowBackIosNewRoundedIcon css={arrows} />,

  };


  const fakeElements = Array.from(
    { length: props.numberOfPlaceholderItems ?? 5 },
    (v, i) => i
  );

  return (
    <Grid container justifyContent="center">
      <Grid item xs={11}>
        <SlickSlider {...settings}>
          {!props?.children &&
            props.enablePlaceholder &&
            fakeElements.map((e, i) =>
              React.cloneElement(props.placeholderItem as any, { key: i })
            )}
          {props?.children && props.children}
        </SlickSlider>
      </Grid>
    </Grid>
  );
}

export default CustomCarrusel;
