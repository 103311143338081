import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getRealestate,
  IRealestateFilters,
} from "api/realestate.request";
import React from "react";
import CustomCarrusel from "../custom-carrusel";
import { MessageResults } from "../messages-result";
import PropertyCollectionCard from "../property-collection-card";
import { Grid, Skeleton } from "@mui/material";
import styled from "@emotion/styled";

interface ISliderCollectionProperty {
  filter?: IRealestateFilters;
  arrowColor?: string;
  children?: JSX.Element;
  title?: string;
  subTitle?: string;
  withoutResultsComponent?: JSX.Element
}
function SliderCollectionProperty(props: ISliderCollectionProperty) {
  const { arrowColor, filter = {} } = props;
  const fetchProjectSlider = async (e: any) => {
    const response = await getRealestate({
      page: 1,
      collection: true,
      exclusives: false,
      ...filter,
    });
    return response;
  };

  const { data, isLoading, isError, mutate } = useMutation(
    ["project-slider-collection"],
    fetchProjectSlider
  );

  React.useEffect(() => {
    mutate({});
  }, []);

  if (data && data?.data?.length == 0 && !isLoading) {
    return <section>{props?.withoutResultsComponent}</section>;
  }

  if (isLoading) {
    return <MessageResults message="Cargando..." />;
  }

  if (isError) {
    return <MessageResults message="Ha ocurrido un error" />;
  }

  return (
    <section className="container">
      {props.title && (
        <Title>
          {props.title} <TitleSpan>{props.subTitle}</TitleSpan>
        </Title>
      )}
      <Wrapper>
        <CustomCarrusel
          colorArrow={arrowColor ?? "#fff"}
          placeholderItem={<PropertyCollectionCardSkeleton />}
          enablePlaceholder={true}
          numberOfPlaceholderItems={10}
          forcePlaceHolder={false}
        >
          {data?.data.map((item: any, i) => (
            <PropertyCollectionCard key={i} {...item}></PropertyCollectionCard>
          ))}
        </CustomCarrusel>
      </Wrapper>
      {props.children}
    </section>
  );
}

export default SliderCollectionProperty;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 5px;
`;

const Title = styled.h2`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.9rem;
  margin-bottom: 30px;
  color: var(--color-blue-main);
  line-height: 40px;
`;

const TitleSpan = styled.span`
  font-weight: 300;
`;

const PropertyCollectionCardSkeleton = () => {
  return (
    <Grid
      container
      flexDirection="column"
      item
      sx={{ maxWidth: "250px", padding: "0 5px" }}
    >
      <Skeleton
        variant="rounded"
        sx={{
          width: "50%",
          marginLeft: 1.5,
          borderRadius: "5px 5px 0px 0px",
          background: "#ffffff36",
        }}
        height={20}
      />
      <Skeleton
        variant="rounded"
        sx={{ width: "100%", borderRadius: 3, background: "#ffffff36" }}
        height={150}
      />
      <Grid container item xs={12}>
        <Skeleton
          variant="text"
          sx={{
            fontSize: "1.6rem",
            width: "100%",
            margin: "auto",
            background: "#ffffff36",
          }}
        />
      </Grid>
    </Grid>
  );
};
