import React from "react";
import Slider, { Settings } from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SlickSlider(props: Settings) {
  return <Slider {...props} />;
}

export default SlickSlider;
